<template>
    <div class="hs2Com2">
        <div class="hs22Box">
            <!-- 标题 -->
            <div class="hs22Title">特殊时期，转型正是当下</div>
            <!-- 左边图片 -->
            <div class="hs22Left">
                <img src="http://iv.okvu.cn/vugw/img/hc3221.png" alt="" class="hs22Img" />
            </div>
            <!-- 右边文字 -->
            <div class="hs22Right">
                <ul>
                    <li class="hs22Li">
                        <div class="hs22Num">01</div>
                        <div class="hs22Text">隔离人，不隔离经济，特殊时期要有不同的应对策略</div>
                    </li>
                    <li class="hs22Li">
                        <div class="hs22Num">02</div>
                        <div class="hs22Text">政策扶持，鼓励线下转线上的互动创新</div>
                    </li>
                    <li class="hs22Li">
                        <div class="hs22Num">03</div>
                        <div class="hs22Text">打破困境，不受时间与空间的限制，在家就能卖货</div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Hs2Com2",
};
</script>

<style lang="less" scoped>
.hs2Com2 {
    min-width: 1423px;
    height: 500px;
    margin-top: 50px;
    background-color: white;
    .hs22Box {
        width: 1280px;
        height: 100%;
        margin: 0 auto;
        // 标题
        .hs22Title {
            width: 50%;
            height: 50px;
            line-height: 50px;
            text-align: center;
            font-size: 40px;
            font-weight: 900;
            margin: 0 auto;
        }
        // 左图
        .hs22Left {
            width: 40%;
            height: 100%;
            float: left;
            margin-top: 50px;
            margin-left: 30px;
        }
        // 右文
        .hs22Right {
            width: 50%;
            height: 50%;
            float: right;
            margin-top: 100px;
            ul {
                width: 100%;
                height: 100%;
                display: flex;
                flex-wrap: wrap;
                .hs22Li {
                    width: 100%;
                    height: 70px;
                    line-height: 70px;
                    border-radius: 15px;
                    text-align: center;
                    background-color: #eee;
                    transition: 0.5s;
                    .hs22Num {
                        width: 80px;
                        height: 100%;
                        float: left;
                        font-size: 30px;
                        color: white;
                        background-color: skyblue;
                        border-top-left-radius: 15px;
                        border-bottom-left-radius: 15px;
                    }
                    .hs22Text {
                        width: 400px;
                        height: 100%;
                        float: left;
                        font-size: 16px;
                    }
                }
                .hs22Li:nth-child(2) {
                    margin-left: 50px;
                    .hs22Num {
                        background-color: pink;
                    }
                }
                .hs22Li:nth-child(3) {
                    margin-left: 100px;
                    .hs22Num {
                        background-color: greenyellow;
                    }
                }
                .hs22Li:hover {
                    transform: translateX(-20px);
                }
            }
        }
    }
}
</style>
