<template>
    <div class="hs2Com1" v-if="getAdvData(22).children">
        <img v-if="getAdvData(22).children[0].image" :src="getAdvData(22).children[0].image" />
        <a :href="getAdvData(22).children[0].url" target="_blank" rel="noopener noreferrer">
            <div class="hs21Left">
                <p class="hs21Big">即刻搭建线上商城</p>
                <p class="hs21Small">让更多人帮你卖货 无需开发|快速开店</p>
                <div class="hs21Btn">
                    免费报价
                    <div class="h2Code">
                        <img :src="getWebData().wechat_visitor" alt="" />
                    </div>
                </div>
                <div class="hs21Btn">
                    立即咨询
                    <div class="h2Code">
                        <img :src="getWebData().public_phone" alt="" />
                    </div>
                </div>
            </div>
        </a>
    </div>
</template>

<script>
export default {
    name: "Hs2Com1",
    inject: ["getWebData", "getAdvData"],
};
</script>

<style lang="less" scoped>
.hs2Com1 {
    min-width: 1423px;
    height: 600px;
    position: relative;
    // background-size: auto 100% !important;
    & > img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        top: 0px;
        left: 0px;
    }
    // 文字
    .hs21Left {
        width: 500px;
        height: 100%;
        margin-left: 100px;
        color: white;
        position: relative;
        z-index: 2;
        .hs21Big {
            width: 100%;
            height: 80px;
            line-height: 80px;
            text-align: center;
            font-size: 45px;
            font-weight: 900;
            padding-top: 100px;
            box-sizing: content-box;
        }
        .hs21Small {
            width: 100%;
            height: 50px;
            line-height: 50px;
            text-align: center;
            font-size: 30px;
        }
        .hs21Btn {
            width: 150px;
            height: 50px;
            line-height: 50px;
            float: left;
            text-align: center;
            border-radius: 25px;
            border: 1px solid white;
            margin-top: 100px;
            margin-left: 80px;
            font-size: 20px;
            color: white;
            cursor: pointer;
            position: relative;
            .h2Code {
                width: 100px;
                height: 100px;
                position: absolute;
                top: -110px;
                left: 25px;
                border-radius: 10px;
                overflow: hidden;
                opacity: 0;
                transition: 0.5s;
                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }
        .hs21Btn:hover {
            box-shadow: 1px 1px 10px 1px #eee;
            .h2Code {
                opacity: 1;
            }
        }
    }
}
</style>
