<template>
    <div class="hs2Com4">
        <div class="hs24Box">
            <!-- 标题 -->
            <div class="hs24Title">
                <p class="hs24Big">线下转型线上的优势</p>
                <p class="hs24Small">以线下资源为铺垫，将线上及线下的优势完美结合</p>
                <p class="hs24Small">扩宽店铺发展方向，让规模走向多元化</p>
            </div>
            <!-- 图文列表 -->
            <div class="hs24ImgText">
                <ul>
                    <li class="hs24Li">
                        <img src="http://iv.okvu.cn/vugw/img/hc3241.png" alt="" />
                        <div class="hs24Text">
                            <p>渠道多元</p>
                            <p>
                                多渠道引入资源<br />
                                降低流量成本
                            </p>
                        </div>
                    </li>
                    <li class="hs24Li">
                        <img src="http://iv.okvu.cn/vugw/img/hc3242.png" alt="" />
                        <div class="hs24Text">
                            <p>利润增加</p>
                            <p>
                                线上资源增加<br />
                                整体利润提升
                            </p>
                        </div>
                    </li>
                    <li class="hs24Li">
                        <img src="http://iv.okvu.cn/vugw/img/hc3243.png" alt="" />
                        <div class="hs24Text">
                            <p>同步信息</p>
                            <p>
                                打通线上线下信息环<br />
                                避免信息不对称
                            </p>
                        </div>
                    </li>
                    <li class="hs24Li">
                        <img src="http://iv.okvu.cn/vugw/img/hc3244.png" alt="" />
                        <div class="hs24Text">
                            <p>流量变现</p>
                            <p>
                                线上结合线下，为店铺带<br />
                                来更多高粘度消费者
                            </p>
                        </div>
                    </li>
                    <li class="hs24Li">
                        <img src="http://iv.okvu.cn/vugw/img/hc3245.png" alt="" />
                        <div class="hs24Text">
                            <p>精准营销</p>
                            <p>
                                方便商家数据搜集，达<br />
                                成精准营销的目的
                            </p>
                        </div>
                    </li>
                    <li class="hs24Li">
                        <img src="http://iv.okvu.cn/vugw/img/hc3246.png" alt="" />
                        <div class="hs24Text">
                            <p>流量变现</p>
                            <p>
                                线上结合线下，为店铺带<br />
                                来更多高粘度消费者
                            </p>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Hs2Com4",
};
</script>

<style lang="less" scoped>
.hs2Com4 {
    min-width: 1423px;
    height: 500px;
    margin-top: 50px;
    .hs24Box {
        width: 1280px;
        height: 100%;
        margin: 0 auto;
        // 标题
        .hs24Title {
            width: 50%;
            height: 100px;
            margin: 0 auto;
            text-align: center;
            .hs24Big {
                width: 100%;
                height: 50px;
                line-height: 50px;
                font-size: 40px;
                font-weight: 900;
            }
            .hs24Small {
                width: 100%;
                height: 25px;
                line-height: 25px;
            }
        }
        // 图文列表
        .hs24ImgText {
            width: 100%;
            height: 350px;
            margin-top: 30px;
            ul {
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: space-around;
                .hs24Li {
                    width: 180px;
                    height: 250px;
                    margin-top: 50px;
                    background-color: #fff;
                    transition: 0.5s;
                    .hs24Text {
                        width: 100%;
                        height: 100px;
                        text-align: center;
                        border-bottom-left-radius: 10px;
                        border-bottom-right-radius: 10px;
                        box-shadow: 3px 3px 10px 3px #eee;
                        p:nth-child(1) {
                            width: 100%;
                            height: 50px;
                            line-height: 50px;
                            font-size: 25px;
                            font-weight: 900;
                            color: skyblue;
                        }
                        p:nth-child(2) {
                            width: 100%;
                            height: 50px;
                            line-height: 25px;
                            font-size: 15px;
                        }
                    }
                }
                .hs24Li:hover {
                    transform: translateY(-10px);
                }
            }
        }
    }
}
</style>
