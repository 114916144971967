<template>
    <div class="hsContent2">
        <!-- 内容一 -->
        <Hs2Com1></Hs2Com1>
        <!-- 内容二 -->
        <Hs2Com2></Hs2Com2>
        <!-- 内容三 -->
        <Hs2Com3></Hs2Com3>
        <!-- 内容四 -->
        <Hs2Com4></Hs2Com4>
        <!-- 内容五 -->
        <Hs2Com5 :list="list"></Hs2Com5>
        <!-- 内容六 -->
        <Hs2Com6></Hs2Com6>
        <!-- 内容七 -->
        <Hs2Com7 :list="list"></Hs2Com7>
    </div>
</template>

<script>
import axios from "axios";
import Hs2Com1 from "./Hs2Com1.vue";
import Hs2Com2 from "./Hs2Com2.vue";
import Hs2Com3 from "./Hs2Com3.vue";
import Hs2Com4 from "./Hs2Com4.vue";
import Hs2Com5 from "./Hs2Com5.vue";
import Hs2Com6 from "./Hs2Com6.vue";
import Hs2Com7 from "./Hs2Com7.vue";

export default {
    name: "HsContent2",
    components: {
        Hs2Com1,
        Hs2Com2,
        Hs2Com3,
        Hs2Com4,
        Hs2Com5,
        Hs2Com6,
        Hs2Com7,
    },
    data() {
        return {
            list: [],
        };
    },
    async created() {
        const { data } = await axios.get("api/project/weMall");
        this.list = data;
    },
};
</script>

<style lang="less" scoped>
.hsContent2 {
}
</style>
