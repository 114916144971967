<template>
    <div class="hs2Com5">
        <div class="hs25Box">
            <!-- 标题 -->
            <div class="hs25Title">
                <p class="hs25Big">客户案例</p>
                <p class="hs25Small">SUCCESS CASE</p>
            </div>
            <!-- 图片列表 -->
            <div class="hs25Imgs">
                <ul>
                    <li class="hs25Li" v-for="el in list" :key="el.id" @click="goDetail(el)">
                        <!-- <img src="http://iv.okvu.cn/vugw/img/hc3251.png" /> -->
                        <img :src="el.logo" />
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Hs2Com5",
    props: ["list"],
    methods: {
        goDetail({ id, ueditorData, project_url }) {
            if (ueditorData == "" || ueditorData == "<p><br></p>" || ueditorData == null) {
                if (!project_url) return;
                if (project_url.indexOf("http") >= 0 || project_url.indexOf("https") >= 0) {
                    window.open(project_url);
                } else {
                    window.open("http://" + project_url);
                }
                return;
            }
            // 点击后的回调
            this.$emit("callBack");
        },
    },
};
</script>

<style lang="less" scoped>
.hs2Com5 {
    min-width: 1423px;
    height: 700px;
    margin-top: 50px;
    background-color: rgba(238, 238, 238, 0.14);
    .hs25Box {
        width: 1280px;
        height: 100%;
        margin: 0 auto;
        overflow: hidden;
        // 标题
        .hs25Title {
            width: 50%;
            height: 80px;
            margin: 0 auto;
            margin-top: 65px;
            text-align: center;
            .hs25Big {
                width: 100%;
                height: 50px;
                line-height: 50px;
                font-size: 40px;
                font-weight: 900;
            }
            .hs25Small {
                width: 100%;
                height: 30px;
                line-height: 30px;
                font-size: 25px;
            }
        }
        // 图片列表
        .hs25Imgs {
            width: 100%;
            height: 450px;
            margin-top: 50px;
            ul {
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: space-around;
                flex-wrap: wrap;
                text-align: center;
                .hs25Li {
                    width: 19%;
                    height: 150px;
                    cursor: pointer;
                    // overflow: hidden;
                    img {
                        width: 80%;
                        height: 80%;
                        margin: 0 auto;
                        display: block;
                        margin-top: 15px;
                        object-fit: contain;
                        border-radius: 20px;
                        transition: 0.2s;
                    }
                }
                img:hover {
                    box-shadow: 1px 1px 10px 1px gray;
                }
            }
        }
    }
}
</style>
